/**
 * pageCache
 * @author wwl
 * @created 2024/3/19
 * @modified 2024/3/19
 */
// import LRU from 'lru-cache';
import {ServerMiddleware} from '@nuxt/types';
const LRU = require('lru-cache');

const cache = new LRU({
  max: 100, // 缓存队列长度 最大缓存数量
  maxAge: 1000 * 3600, // 缓存时间600秒 单位：毫秒 3600秒=1小时
});

const cacheMiddleware: ServerMiddleware = function (req, res, next) {
  console.log("pageCache中间件",next)
  if (process.env.NODE_ENV !== 'development' || process.env.NODE_ENV == 'development') {
    try {
      const pathname = <string>req.originalUrl;
       // 清理缓存
      if (pathname === '/cleancache') {
        cache.reset();
        res.statusCode = 200;
      }
      // 只有首页才进行缓存 pathname.startsWith('/exhibition')
      if (['/'].includes(pathname) || pathname.startsWith('/information')) {
        const existsHtml = cache.get(pathname);
        if (existsHtml) {
          console.warn(`${new Date().toJSON()} 命中缓存 >>>`, pathname, existsHtml.html?.length);
          //  如果没有Content-Type:text/html 的 header，gtmetrix网站无法做测评
          res.setHeader('Content-Type', ' text/html; charset=utf-8');
          return res.end(existsHtml.html, 'utf-8');
        } else {
          (res as any).original_end = res.end;
          res.end = function(data: any) {
            console.warn(`${new Date().toJSON()} 写入缓存 >>>`, pathname, res.statusCode, data?.length);
            if (res.statusCode === 200) {
              // 设置缓存
              cache.set(pathname, {
                html: data,
              });
            }
            (res as any).original_end(data, 'utf-8');
          };
        }
      }
    } catch(error) {
      console.log(`page-cache-middleware: ${error}`)
      next()
    }
  }
  next()
}

export default cacheMiddleware;
