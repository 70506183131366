import { ServerMiddleware } from '@nuxt/types';
import axios from 'axios';
import uuid from 'uuid';
import _ from 'lodash';

const exposureMiddleware: ServerMiddleware = (req, _res, next) => {
  if (!req.url?.startsWith('/_') && !req.url?.startsWith('/api')) {
    let baseUrl = '';

    let module = 'MO_COMMON';
    let callbackCode = '';
    let bizId = '';

    if (/\/exhibition\/(\d+)\.html/.test(req.url || '')) {
      module = 'MO_FAIR';
      callbackCode = 'CC_FAIR_DETAIL';
      bizId = _.get(/\/exhibition\/(\d+)\.html/.exec(req.url || ''), '[1]', '');
    } else if (/\/exhibition\/apply\/(\d+)\.html/.test(req.url || '')) {
      module = 'MO_FAIR';
      callbackCode = 'MO_FAIR_BOOTH';
      bizId = _.get(
        /\/exhibition\/apply\/(\d+)\.html/.exec(req.url || ''),
        '[1]',
        ''
      );
    } else if (/\/exhibition\/periodical\/(\d+)\.html/.test(req.url || '')) {
      module = 'MO_FAIR';
      callbackCode = 'MO_FAIR_PERIODICAL';
      bizId = _.get(
        /\/exhibition\/periodical\/(\d+)\.html/.exec(req.url || ''),
        '[1]',
        ''
      );
    } else if (/\/exhibition\/ticket\/(\d+)\.html/.test(req.url || '')) {
      module = 'MO_FAIR';
      callbackCode = 'MO_FAIR_TICKET';
      bizId = _.get(
        /\/exhibition\/ticket\/(\d+)\.html/.exec(req.url || ''),
        '[1]',
        ''
      );
    } else if (/\/exhibition/.test(req.url || '')) {
      module = 'MO_FAIR';
      callbackCode = 'CC_FAIR_LIST';
    } else if (/\/information\/(\d+)\.html/.test(req.url || '')) {
      module = 'MO_NEWS';
      callbackCode = 'CC_NEWS_DETAIL';
      bizId = _.get(
        /\/information\/(\d+)\.html/.exec(req.url || ''),
        '[1]',
        ''
      );
    } else if (/\/information(\/?)/.test(req.url || '')) {
      module = 'MO_NEWS';
      callbackCode = 'CC_NEWS_LIST';
    }

    if (process.env.MODE === 'dev') {
      baseUrl = 'http://127.0.0.1:7001';
    } else if (process.env.MODE === 'test') {
      baseUrl = 'https://www.jufair.com';
    } else {
      baseUrl = 'https://www.jufair.com';
    }

    const data = {
      source: 'local',
      terminal: 'PC',
      channel: 'PC',
      referUri: req.originalUrl,
      module,
      callbackCode,
      bizId,
      requestId: uuid.v4(),
    };

    let clientIP =
      req.headers['x-forwarded-for'] || req.headers['x-real-ip'] || '';
    if (Array.isArray(clientIP)) {
      clientIP = clientIP[0];
    } else {
      clientIP = clientIP.split(',')[0];
    }
    const userAgent = req.headers['user-agent'] || '';
    // 获取客户端的 Referer
    const referer = req.headers.referer || req.headers.referrer || '';

    // axios
    //   .post(`${baseUrl}/api/dataCollection/accessRecord`, data, {
    //     headers: {
    //       Cookie: 'bpId=exposure',
    //       'x-forwarded-for': clientIP,
    //       'User-Agent': userAgent,
    //       // "Referer": referer,
    //     },
    //   })
    //   .catch((result) => {
    //     console.error('data >>>', req.headers, data);
    //     console.error('>>>>', baseUrl, result);
    //   });
  }

  next();
};

export default exposureMiddleware;
