import { Middleware } from '@nuxt/types';
import { get } from 'lodash';
import { $axios, $cookies } from '~/util/api';
import { ResponseCode } from '~/util/constant';

const common: Middleware = async (ctx) => {
  // console.log("拦截器中间件",ctx)
  const token = $cookies.get('access_token');
  // console.log("拦截器中间件-9",ctx)

  if (
    ctx.route.fullPath.startsWith('/api') ||
    ctx.route.fullPath.startsWith('/ossApi')
  ) {
    return;
  }

  try {
    if (token) {
      if (!ctx.route.fullPath.includes('.html#')) {
        await ctx.store.dispatch('global/getUser');
      }
    }
  } catch (e: any) {
    console.debug('这里可能会报错 ???', e.message);
  }

  const forbidCrawler = get(ctx.route, 'meta[0].forbidCrawler');

  if (forbidCrawler === false) return;

  const uid = $cookies.get('uid');
  ctx.store.commit('global/setIsLogin', !!token);

  // 获取客户端的 IP 地址
  let clientIP =
    ctx.req?.headers['x-forwarded-for'] || ctx.req?.headers['x-real-ip'] || '';
  if (Array.isArray(clientIP)) {
    clientIP = clientIP[0];
  } else {
    clientIP = clientIP.split(',')[0];
  }
  // 获取客户端的 User-Agent
  const userAgent = ctx.req?.headers['user-agent'] || '';
  // 获取客户端的 Referer
  const referer = ctx.req?.headers.referer || ctx.req?.headers.referrer || '';

  const headers: Record<string, any> = {};

  if (process.server) {
    headers['x-forwarded-for'] = clientIP;
    headers['User-Agent'] = userAgent;
    // headers.Referer = referer;
  }

  let res;

  if (uid) {
    res = <any>await $axios.post(
      `/api/dataCollection/forbidCrawler?userId=${uid}`,
      null,
      {
        headers,
      }
    );
  } else {
    res = <any>await $axios.post('/api/dataCollection/forbidCrawler', null, {
      headers,
    });
  }

  if (res.code === ResponseCode.BLACK_LOGIN) {
    ctx.error({
      message: '先登录！',
      path: ctx.route.fullPath,
      statusCode: 504,
    });
  }
};

export default common;
